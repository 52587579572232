import React, { useState } from 'react'
import LockedLogo from 'images/lockedlogo.png'
import { cookies } from 'scripts/cookies'
import axios from 'axios'
import { fullUrl } from 'scripts/fullUrl'
import { navigate } from 'gatsby'
import { LogoutResponse } from 'types/ApiResponse'

export const HeaderSmallWithAccessRequest: React.FC = () => {
  const [spMenuModalIsOpen, setSpMenuModalIsOpen] = useState(false)

  const handleClickHamburgerMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault()
    setSpMenuModalIsOpen(true)
  }

  const handleClickCloseMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault()
    setSpMenuModalIsOpen(false)
  }

  const handleClickApps = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    navigate('/apps')
  }

  const handleClickAccessRequestApprove = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault()
    navigate('/accessRequestApprove')
  }

  const handleClickLogout = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault()
    const sessionToken = cookies.get('session_token')
    axios
      .get<LogoutResponse>(`${fullUrl()}/logout`, {
        params: {
          session_token: sessionToken,
        },
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        cookies.removeSessionToken()
        navigate('/login')
      })
  }

  const renderSpMenuModal = () => {
    if (spMenuModalIsOpen) {
      return (
        <div
          className="fixed top-0 left-0 z-10 px-6"
          style={{ width: '100vw', height: '100vh', background: '#FFF' }}
        >
          <div
            className="flex justify-between items-center py-5"
            style={{ height: 60 }}
          >
            <img
              src={LockedLogo}
              style={{
                width: 101,
                display: 'block',
              }}
            />
            <button className="p-2" onClick={handleClickCloseMenu}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.3337 2.55366L17.447 0.666992L10.0003 8.11366L2.55366 0.666992L0.666992 2.55366L8.11366 10.0003L0.666992 17.447L2.55366 19.3337L10.0003 11.887L17.447 19.3337L19.3337 17.447L11.887 10.0003L19.3337 2.55366Z"
                  fill="#828282"
                />
              </svg>
            </button>
          </div>
          <div className="" style={{ marginTop: '40vh' }}>
            <div
              className="cursor-pointer"
              onClick={handleClickApps}
              style={{
                fontSize: '20px',
                lineHeight: '156.5%',
                letterSpacing: '0.03em',
                color: '#333333',
                marginBottom: '28px',
              }}
            >
              アプリ一覧
            </div>
            <div
              className="cursor-pointer"
              onClick={handleClickAccessRequestApprove}
              style={{
                fontSize: '20px',
                lineHeight: '156.5%',
                letterSpacing: '0.03em',
                color: '#333333',
                marginBottom: '28px',
              }}
            >
              権限の承認
            </div>
            <a
              href="https://www.notion.so/onetapincjp/LOCKED-d354ce025e0f42ff9f143ce00f0a9410"
              target="_blank"
            >
              <div
                className="flex items-center"
                style={{
                  fontSize: '20px',
                  lineHeight: '156.5%',
                  letterSpacing: '0.03em',
                  color: '#333333',
                  marginBottom: '28px',
                }}
              >
                <span className="mr-2">よくある質問</span>
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7778 18.2778H2.22222V2.72222H10V0.5H2.22222C0.994444 0.5 0 1.49444 0 2.72222V18.2778C0 19.5056 0.994444 20.5 2.22222 20.5H17.7778C19.0056 20.5 20 19.5056 20 18.2778V10.5H17.7778V18.2778ZM12.2222 0.5V2.72222H16.2056L5.28333 13.6444L6.85556 15.2167L17.7778 4.29444V8.27778H20V0.5H12.2222Z"
                    fill="#2D9CDB"
                  />
                </svg>
              </div>
            </a>
            <div
              className="cursor-pointer"
              onClick={handleClickLogout}
              style={{
                fontSize: '20px',
                lineHeight: '156.5%',
                letterSpacing: '0.03em',
                color: '#333333',
                marginBottom: '28px',
              }}
            >
              ログアウト
            </div>
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }

  return (
    <>
      {renderSpMenuModal()}
      <div
        className="fixed flex items-center px-6 justify-between"
        style={{
          zIndex: 2,
          height: 60,
          width: '100%',
          background: '#FFF',
          boxShadow: '0px 2px 6px rgba(10, 6, 17, 0.15)',
        }}
      >
        <img
          src={LockedLogo}
          style={{
            width: 101,
            display: 'block',
          }}
        />
        <button className="p-2" onClick={handleClickHamburgerMenu}>
          <svg
            width="24"
            height="16"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 16H24V13.3333H0V16ZM0 9.33333H24V6.66667H0V9.33333ZM0 0V2.66667H24V0H0Z"
              fill="#828282"
            />
          </svg>
        </button>
      </div>
    </>
  )
}
