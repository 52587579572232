import { fullUrl } from 'scripts/fullUrl'
import { CancelAccessRequestInstancesResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'

export const putAccessRequestInstanceCancel = async (params: {
  sessionToken: string
  id: string
  user_ip: string
}): Promise<CancelAccessRequestInstancesResponse> => {
  const res = await axiosClient.put<CancelAccessRequestInstancesResponse>(
    `${fullUrl('api')}/access_request/access_request_instances/${
      params.id
    }/cancel`,
    params,
  )
  return res.data
}
