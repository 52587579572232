import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
} from 'react'
import { cookies } from 'scripts/cookies'
import {
  Box,
  Button,
  Paper,
  Tab,
  Tabs,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Tooltip,
  SxProps,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  Avatar,
} from '@mui/material'
import {
  DataGridPremium,
  GridRowParams,
  GridSelectionModel,
  GRID_ACTIONS_COLUMN_TYPE,
  jaJP,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridColumns,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridOverlay,
} from '@mui/x-data-grid-premium'
import { Edit, ZoomIn, Publish, KeyboardArrowRight } from '@mui/icons-material'
import { UserInfoPart } from 'presentation/pages/apps/Common/Header/Large/UserInfoPart/userInfo'
import { LogoPartForAccessRequest } from 'presentation/pages/apps/Common/Header/Large/LogoPartForAccessRequest'
import { HeaderSmallWithAccessRequest } from 'presentation/pages/apps/Common/Header/Small/headerWithAccessRequest'
import { getAccessRequestInstances } from 'api/getAccessRequestInstances'
import { postAccessRequestInstanceCreate } from 'api/postAccessRequestInstanceCreate'
import { putAccessRequestInstanceCancel } from 'api/putAccessRequestInstanceCancel'
import { getAccessRoles } from 'api/getAccessRoles'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { navigate } from 'gatsby'
import {
  AccessProfile,
  AccessRequestInstance,
  AccessRole,
  ResourceApp,
  Approval,
  Entitlement,
  Resource,
  AppEntitlementSchema,
} from 'types/ApiResponse'
import { useIpAddress } from 'api/useIpAddress'
import { getEnduser } from 'api/getEnduser'
import { LARGE_APP_LIST_MAX_WIDTH } from '../apps/AppExists/Large/AppList'
import { Helmet } from 'react-helmet'
import { getAccessProfiles } from 'api/getAccessProfiles'
import { getEntitlements } from 'api/getEntitlements'
import { getResources } from 'api/getResources'
import { getResourceApps } from 'api/getResourceApps'
import { grey } from '@mui/material/colors'
import { getAppEntitlementSchemas } from 'api/getAppEntitlementSchemas'

// TODO: アクセスロール以外の申請、DAS側のAPI実装が完了後に対応
// TODO: useTranslation()を使って多言語化する

const tabTypes = {
  ACCESS_ITEMS: 'access_items',
  ACCESS_REQUEST_INSTANCES: 'access_request_instances',
} as const

const accessItemTabTypes = {
  ACCESS_ROLE: 'access_role',
  ACCESS_PROFILE: 'access_profile',
  ENTITLEMENT: 'entitlement',
} as const

interface Action {
  type: keyof typeof ACTION_TYPE
  payload: Partial<State>
}

const ACTION_TYPE = {
  initialize: 'initialize',
  changeTabType: 'changeTabType',
  changeAccessItemTabType: 'changeAccessItemTabType',
  changeSelectedAccessItemIds: 'changeSelectedAccessItemIds',
  changeSelectedAccessRequestInstanceIds:
    'changeSelectedAccessRequestInstanceIds',
  openDetailAccessRequestInstanceDialog:
    'openDetailAccessRequestInstanceDialog',
  closeDetailAccessRequestInstanceDialog:
    'closeDetailAccessRequestInstanceDialog',
  openSubmitAccesRequestDialog: 'openSubmitAccesRequestDialog',
  closeSubmitAccesRequestDialog: 'closeSubmitAccesRequestDialog',
  changeComment: 'changeComment',
  changeDuration: 'changeDuration',
  submitAccessRequest: 'submitAccessRequest',
  openCancelAccessRequestDialog: 'openCancelAccessRequestDialog',
  closeCancelAccessRequestDialog: 'closeCancelAccessRequestDialog',
  cancelAccessRequest: 'cancelAccessRequest',
} as const

const reducer = (state: State, action: Action): State => {
  return {
    ...state,
    ...action.payload,
  }
}

const initialState: State = {
  windowWidth: undefined,
  tabType: tabTypes.ACCESS_ITEMS,
  accessItemTabType: accessItemTabTypes.ACCESS_ROLE,
  sessionToken: '',
  teamName: '',
  userName: '',
  selectedAccessItemIds: [],
  selectedAccessRequestInstanceIds: [],
  detailAccessRequestInstance: undefined,
  comment: '',
  duration: 'unlimited',
  accessRoles: [],
  accessProfiles: [],
  entitlements: [],
  appEntitlementSchemas: [],
  resourceApps: [],
  resources: [],
  accessRequestInstances: [],
  openDetailAccessRequestInstanceDialog: false,
  openSubmitAccesRequestDialog: false,
  openCancelAccessRequestDialog: false,
}

interface State {
  windowWidth: number | undefined
  tabType: typeof tabTypes[keyof typeof tabTypes]
  accessItemTabType: typeof accessItemTabTypes[keyof typeof accessItemTabTypes]
  sessionToken: string
  teamName: string
  userName: string
  selectedAccessItemIds: GridSelectionModel
  selectedAccessRequestInstanceIds: GridSelectionModel
  detailAccessRequestInstance: AccessRequestInstance | undefined
  comment: string
  duration: '1day' | '1week' | '1month' | '3months' | 'unlimited'
  accessRoles: AccessRole[]
  accessProfiles: AccessProfile[]
  entitlements: Entitlement[]
  appEntitlementSchemas: AppEntitlementSchema[]
  resourceApps: ResourceApp[]
  resources: Resource[]
  accessRequestInstances: AccessRequestInstance[]
  openDetailAccessRequestInstanceDialog: boolean
  openSubmitAccesRequestDialog: boolean
  openCancelAccessRequestDialog: boolean
}

// TODO: 以下constants, scriptsは 'page/accessRequestApprove' と共通化できる
// dataGridSxProps
// translateErrorMessageはactionを合わせることで共通化できる
// formatDateTime
// translateStatus
// translateStatusColor
// translateApprovalStatus
// translateApprovalStatusColor
// getApprovalsProgress

const dataGridSxProps: SxProps = {
  border: 'none',
  '& .MuiDataGrid-main': {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.17)',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#e5e5e5',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: '#e5e5e5',
  },
} as const

const translateErrorMessage = (
  reason: string | undefined,
  action: 'get' | 'create' | 'cancel',
) =>
  ({
    get:
      reason === 'does not have locked_das_user_id'
        ? '申請や承認を行うためには、アカウントの紐づけが必要です。詳しくは管理者にご確認ください'
        : reason === 'cannot request access role' ||
          reason === 'cannot request access profile' ||
          reason === 'cannot request entitlement'
        ? '申請や承認の機能を利用することができません。詳しくは管理者にご確認ください'
        : 'データの取得に失敗しました。サポート担当までご連絡ください',
    create:
      reason === 'does not have locked_das_user_id'
        ? '申請や承認を行うためには、アカウントの紐づけが必要です。詳しくは管理者にご確認ください'
        : reason === 'bad input: cannot find requested_by user'
        ? '申請者として登録されていません。詳しくは管理者にご確認ください'
        : reason === 'bad input: cannot find requested_for user'
        ? '対象者として登録されていません。詳しくは管理者にご確認ください'
        : reason === 'bad input: cannot find access_role approval_setting_id'
        ? '承認フロー設定が割り当てられていません。詳しくは管理者にご確認ください'
        : reason ===
          'bad input: access_request_instance approvals is greater than equal to 1'
        ? '承認フロー設定に誤りがあります。サポート担当までご連絡ください。'
        : '申請に失敗しました。サポート担当までご連絡ください',
    cancel:
      reason === 'does not have locked_das_user_id'
        ? '申請や承認を行うためには、アカウントの紐づけが必要です。詳しくは管理者にご確認ください'
        : reason === 'bad input: access request instance is already finished'
        ? 'すでに終了しているため、取り下げできませんでした。サポート担当までご連絡ください。'
        : reason ===
          'bad input: cannot cancel access request instance because requestedBy is not match'
        ? '申請者として指定されていないため、取り下げできませんでした。サポート担当までご連絡ください。'
        : reason ===
          'bad input: cannot cancel access request instance because approvals is empty'
        ? '承認ステップが設定されていないため、取り下げできませんでした。サポート担当までご連絡ください。'
        : reason ===
          'bad input: cannot cancel access request instance because first approval status is not in_review'
        ? '最初の承認ステップのステータスが承認待ちでないため、取り下げできませんでした。サポート担当までご連絡ください。'
        : reason ===
          'bad input: cannot cancel access request instance because first approval approvedBy is not empty'
        ? '最初の承認ステップで、すでに承認または却下を行った承認者がいるため、取り下げできませんでした。サポート担当までご連絡ください。'
        : '取り下げに失敗しました。サポート担当までご連絡ください',
  }[action])

const formatDateTime = (isoString: string) => {
  const date = new Date(isoString)
  const year = date.getFullYear()
  const month = date.getMonth() + 1 // getMonth() は0から始まるため、+1 する
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = ('0' + date.getMinutes()).slice(-2) // 分が1桁の場合は0を追加

  return `${year}年${month}月${day}日 ${hours}時${minutes}分`
}

const translateStatus = (status: AccessRequestInstance['status']) =>
  status === 'in_review'
    ? '承認待ち'
    : status === 'rejected'
    ? '却下'
    : status === 'approved'
    ? '承認済み'
    : status === 'cancelled'
    ? '取り下げ'
    : status === 'terminated'
    ? '強制却下'
    : '不明'

const translateStatusColor = (status: AccessRequestInstance['status']) =>
  status === 'in_review'
    ? '#0288d1'
    : status === 'rejected'
    ? '#d32f2f'
    : status === 'approved'
    ? '#2e7d32'
    : status === 'cancelled'
    ? 'gray'
    : status === 'terminated'
    ? 'gray'
    : 'gray'

const translateApprovalStatus = (status: Approval['status']) =>
  status === 'in_review'
    ? '承認待ち'
    : status === 'rejected'
    ? '却下'
    : status === 'approved'
    ? '承認済み'
    : status === 'pending'
    ? '---'
    : '不明'

const translateApprovalStatusColor = (status: Approval['status']) =>
  status === 'in_review'
    ? '#0288d1'
    : status === 'rejected'
    ? '#d32f2f'
    : status === 'approved'
    ? '#2e7d32'
    : status === 'pending'
    ? 'inherit'
    : 'gray'

const getApprovalsProgress = (approvals: Approval[]) => {
  const totalApprovalsCount = approvals.length
  const inProgressApprovalsCount = approvals.filter(
    (approval) =>
      approval.status === 'in_review' ||
      approval.status === 'approved' ||
      approval.status === 'rejected',
  ).length

  return `${inProgressApprovalsCount} / ${totalApprovalsCount}`
}

const accessRoleGridColumns = ({
  checkboxColumn,
  gridActionItems,
}: {
  checkboxColumn?: typeof GRID_CHECKBOX_SELECTION_COL_DEF
  gridActionItems?: (row: AccessRole) => JSX.Element[]
}): GridColumns<AccessRole> => {
  const columns: GridColumns<AccessRole> = [
    {
      type: 'string',
      field: 'name',
      headerName: '名前',
      width: 500,
      valueGetter: ({ row }) => (row.name === '' ? null : row.name),
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
    {
      type: 'string',
      field: 'description',
      headerName: '説明',
      width: 500,
      valueGetter: ({ row }) =>
        row.description === '' ? null : row.description,
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
  ]
  if (checkboxColumn !== undefined) {
    columns.unshift(checkboxColumn)
  }
  if (gridActionItems !== undefined) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: '詳細',
      width: 50,
      cellClassName: 'actions',
      disableColumnMenu: true,
      getActions: ({ row }) => gridActionItems(row),
    })
  }
  return columns
}

const accessProfileGridColumns = ({
  resourceApps,
  resources,
  checkboxColumn,
  gridActionItems,
}: {
  resourceApps: ResourceApp[]
  resources: Resource[]
  checkboxColumn?: typeof GRID_CHECKBOX_SELECTION_COL_DEF
  gridActionItems?: (row: AccessProfile) => JSX.Element[]
}): GridColumns<AccessProfile> => {
  const columns: GridColumns<AccessProfile> = [
    {
      type: 'singleSelect',
      field: 'resource_id',
      valueOptions: resources.map((r) => r.name),
      headerName: 'アプリ',
      width: 200,
      valueGetter: ({ row }) =>
        resources.find((r) => r.id === row.resource_id)?.name ?? null,
      renderCell: ({ row, value }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {value && (
              <Avatar
                alt={
                  resourceApps.find(
                    ({ id }) =>
                      id ===
                      resources.find((r) => r.id === row.resource_id)?.app_id,
                  )?.logo_image_url
                }
                src={
                  resourceApps.find(
                    ({ id }) =>
                      id ===
                      resources.find((r) => r.id === row.resource_id)?.app_id,
                  )?.logo_image_url
                }
                imgProps={{
                  sx: {
                    objectFit: 'contain',
                    verticalAlign: 'text-bottom',
                  },
                }}
              />
            )}
            <Box paddingLeft={1} />
            {value ?? '- - -'}
          </Box>
        )
      },
    },
    {
      type: 'string',
      field: 'name',
      headerName: '名前',
      width: 350,
      valueGetter: ({ row }) => (row.name === '' ? null : row.name),
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
    {
      type: 'string',
      field: 'description',
      headerName: '説明',
      width: 500,
      valueGetter: ({ row }) =>
        row.description === '' ? null : row.description,
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
  ]
  if (checkboxColumn !== undefined) {
    columns.unshift(checkboxColumn)
  }
  if (gridActionItems !== undefined) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: '詳細',
      width: 50,
      cellClassName: 'actions',
      disableColumnMenu: true,
      getActions: ({ row }) => gridActionItems(row),
    })
  }
  return columns
}

const entitlementGridColumns = ({
  resourceApps,
  resources,
  appEntitlementSchemas,
  checkboxColumn,
  gridActionItems,
}: {
  resourceApps: ResourceApp[]
  resources: Resource[]
  appEntitlementSchemas: AppEntitlementSchema[]
  checkboxColumn?: typeof GRID_CHECKBOX_SELECTION_COL_DEF
  gridActionItems?: (row: Entitlement) => JSX.Element[]
}): GridColumns<Entitlement> => {
  const columns: GridColumns<Entitlement> = [
    {
      type: 'singleSelect',
      field: 'resource_id',
      valueOptions: resources.map((r) => r.name),
      headerName: 'アプリ',
      width: 200,
      valueGetter: ({ row }) =>
        resources.find((r) => r.id === row.resource_id)?.name ?? null,
      renderCell: ({ row, value }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {value && (
              <Avatar
                alt={
                  resourceApps.find(
                    ({ id }) =>
                      id ===
                      resources.find((r) => r.id === row.resource_id)?.app_id,
                  )?.logo_image_url
                }
                src={
                  resourceApps.find(
                    ({ id }) =>
                      id ===
                      resources.find((r) => r.id === row.resource_id)?.app_id,
                  )?.logo_image_url
                }
                imgProps={{
                  sx: {
                    objectFit: 'contain',
                    verticalAlign: 'text-bottom',
                  },
                }}
              />
            )}
            <Box paddingLeft={1} />
            {value ?? '- - -'}
          </Box>
        )
      },
    },
    {
      type: 'string',
      field: 'object_type',
      headerName: '種類',
      width: 200,
      valueGetter: ({ row }) =>
        appEntitlementSchemas
          .find(
            (aes) =>
              aes.app_id ===
              resources.find((r) => r.id === row.resource_id)?.app_id,
          )
          ?.attribute_schemas.find((as) => as.object_type === row.object_type)
          ?.label ?? null,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value ?? '---'} arrow>
            <Box
              data-testid="connection-description-cell"
              textOverflow={'ellipsis'}
              overflow="hidden"
            >
              {value ?? '---'}
            </Box>
          </Tooltip>
        )
      },
    },
    {
      type: 'string',
      field: 'label',
      headerName: '名前',
      width: 500,
      valueGetter: ({ row }) => (row.label === '' ? null : row.label),
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
  ]
  if (checkboxColumn !== undefined) {
    columns.unshift(checkboxColumn)
  }
  if (gridActionItems !== undefined) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: '詳細',
      width: 50,
      cellClassName: 'actions',
      disableColumnMenu: true,
      getActions: ({ row }) => gridActionItems(row),
    })
  }
  return columns
}

const accessRequestInstanceGridColumns = ({
  checkboxColumn,
  gridActionItems,
}: {
  checkboxColumn?: typeof GRID_CHECKBOX_SELECTION_COL_DEF
  gridActionItems?: (row: AccessRequestInstance) => JSX.Element[]
}): GridColumns<AccessRequestInstance> => {
  const columns: GridColumns<AccessRequestInstance> = [
    {
      type: 'dateTime',
      field: 'started_at',
      headerName: '申請日時',
      width: 200,
      renderCell: ({ row }) => (
        <Typography variant="body2">
          {row.started_at !== null ? formatDateTime(row.started_at) : '---'}
        </Typography>
      ),
      getApplyQuickFilterFn: (filterValue: string) => {
        if (!filterValue) return null
        return ({ value }): boolean => {
          if (value === null) return false
          return formatDateTime(value).includes(filterValue)
        }
      },
    },
    {
      type: 'singleSelect',
      valueOptions: ['承認待ち', '却下', '承認済み', '取り下げ', '強制却下'],
      field: 'status',
      headerName: 'ステータス',
      width: 200,
      valueGetter: ({ row }) => translateStatus(row.status),
      renderCell: ({ row, value }) => (
        <Typography variant="body2" color={translateStatusColor(row.status)}>
          {`${value} (${getApprovalsProgress(row.approvals)})`}
        </Typography>
      ),
      getApplyQuickFilterFn: (filterValue: string) => {
        if (!filterValue) return null
        return ({ row, value }): boolean => {
          if (value === null) return false
          const statusValue = `${value} (${getApprovalsProgress(
            row.approvals,
          )})`
          return statusValue.includes(filterValue)
        }
      },
    },
    {
      type: 'string',
      field: 'requested_object.name',
      headerName: '権限',
      width: 300,
      valueGetter: ({ row }) =>
        row.requested_object.name === '' ? null : row.requested_object.name,
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
    {
      type: 'string',
      field: 'requested_by.name',
      headerName: '申請者',
      width: 200,
      valueGetter: ({ row }) =>
        row.requested_by.name === '' ? null : row.requested_by.name,
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
    {
      type: 'string',
      field: 'requested_by.comment',
      headerName: 'コメント',
      width: 200,
      valueGetter: ({ row }) =>
        row.requested_by.comment === '' ? null : row.requested_by.comment,
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
    {
      type: 'dateTime',
      field: 'expired_at',
      headerName: '有効期限',
      width: 200,
      renderCell: ({ row }) => (
        <Typography variant="body2">
          {row.expired_at !== null ? formatDateTime(row.expired_at) : '---'}
        </Typography>
      ),
      getApplyQuickFilterFn: (filterValue: string) => {
        if (!filterValue) return null
        return ({ value }): boolean => {
          if (value === null) return false
          return formatDateTime(value).includes(filterValue)
        }
      },
    },
    {
      type: 'string',
      field: 'id',
      headerName: '申請ID',
      width: 300,
      valueGetter: ({ row }) => (row.id === '' ? null : row.id),
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
  ]
  if (checkboxColumn !== undefined) {
    columns.unshift(checkboxColumn)
  }
  if (gridActionItems !== undefined) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: '詳細',
      width: 50,
      cellClassName: 'actions',
      disableColumnMenu: true,
      getActions: ({ row }) => gridActionItems(row),
    })
  }
  return columns
}

export const AccessRequest = (): JSX.Element => {
  const [openSnackbar] = useSnackbar()
  const [{ ipAddress }] = useIpAddress()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isLoading, setIsLoading] = useState(false)

  const isAccessRequestInstanceRowSelectable = useCallback(
    (params: GridRowParams<AccessRequestInstance>) =>
      params.row.status === 'in_review' &&
      !params.row.approvals.some(
        (approval) =>
          approval.status === 'approved' || approval.status === 'rejected',
      ),
    [],
  )

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)

      // TODO: MSO利用がないテナントを用意する必要が発生した場合に、テナント特定のためにアプリ一覧で行っているsubdomainの取得処理を追加
      // const subdomain = getSubdomain(window.location.host)

      const sessionToken = cookies.get('session_token')
      if (!sessionToken) {
        navigate('/login', { replace: true })
        return
      }
      const enduserResponse = await getEnduser()
      if (enduserResponse.status !== 200) {
        navigate('/login', { replace: true })
        return
      }
      const { first_name, middle_name, last_name } =
        enduserResponse.data.enduser
      const userName = `${last_name} ${middle_name ?? ''} ${first_name}`

      try {
        const responses = await Promise.all([
          getAccessRoles({ sessionToken }),
          getAccessProfiles({ sessionToken }),
          getEntitlements({ sessionToken }),
          getAppEntitlementSchemas({ sessionToken }),
          getResources({ sessionToken }),
          getResourceApps({ sessionToken }),
          getAccessRequestInstances({ sessionToken, type: 'requested_by' }),
        ])
        if (responses.some((r) => r.status !== 200)) {
          if (
            responses.find((r) => r.status !== 200)?.reason ===
            'could not find enduser'
          ) {
            navigate('/login', { replace: true })
            return
          }
          openSnackbar(
            translateErrorMessage(
              responses.find((r) => r.status !== 200)?.reason,
              'get',
            ),
          )
          return
        }
        const [
          accessRolesRes,
          accessProfilesRes,
          entitlementsRes,
          appEntitlementSchemasRes,
          resourcesRes,
          resourceAppsRes,
          accessRequestInstancesRes,
        ] = responses
        dispatch({
          type: ACTION_TYPE.initialize,
          payload: {
            windowWidth: window?.innerWidth,
            sessionToken,
            teamName: enduserResponse.data.enduser.team_name,
            userName,
            accessRoles: accessRolesRes.access_roles,
            accessProfiles: accessProfilesRes.access_profiles,
            entitlements: entitlementsRes.entitlements,
            appEntitlementSchemas:
              appEntitlementSchemasRes.app_entitlement_schemas,
            resources: resourcesRes.resources,
            resourceApps: resourceAppsRes.apps,
            accessRequestInstances: [
              ...accessRequestInstancesRes.instances,
            ].reverse(),
          },
        })
      } catch (error) {
        openSnackbar(
          'システムエラーが発生しました。サポート担当までご連絡ください。',
        )
        throw error
      }

      // TODO: MSO利用がないテナントを用意する必要が発生した場合に、テナント特定のためにアプリ一覧で行っているsubdomainの取得処理を追加
      // // ログインできたら、subdomainを記録
      // if (subdomain) {
      //   cookies.setSessionTokenToCookie(sessionToken, 123)
      //   cookies.setAuthSubdomainToCookie(subdomain)
      // }

      setIsLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeTab = useCallback(
    (
      event: React.SyntheticEvent,
      newValue: typeof tabTypes[keyof typeof tabTypes],
    ) =>
      dispatch({
        type: ACTION_TYPE.changeTabType,
        payload: {
          tabType: newValue,
        },
      }),
    [],
  )

  const handleChangeAccessItemTab = useCallback(
    async (
      newValue: typeof accessItemTabTypes[keyof typeof accessItemTabTypes],
    ) =>
      dispatch({
        type: ACTION_TYPE.changeAccessItemTabType,
        payload: {
          accessItemTabType: newValue,
          selectedAccessItemIds: [],
        },
      }),
    [],
  )

  const handleChangeSelectedAccessItemIds = useCallback(
    (selectionModel: GridSelectionModel) => {
      if (selectionModel.length > 1) {
        selectionModel.shift()
      }
      dispatch({
        type: ACTION_TYPE.changeSelectedAccessItemIds,
        payload: {
          selectedAccessItemIds: selectionModel,
        },
      })
    },
    [],
  )

  const handleChangeSelectedAccessRequestInstanceIds = useCallback(
    (selectionModel: GridSelectionModel) => {
      dispatch({
        type: ACTION_TYPE.changeSelectedAccessRequestInstanceIds,
        payload: {
          selectedAccessRequestInstanceIds: selectionModel,
        },
      })
    },
    [],
  )

  const handleOpenDetailAccessRequestInstanceDialog = (
    row: AccessRequestInstance,
  ) => {
    dispatch({
      type: ACTION_TYPE.openDetailAccessRequestInstanceDialog,
      payload: {
        detailAccessRequestInstance: row,
        openDetailAccessRequestInstanceDialog: true,
      },
    })
  }

  const handleCloseDetailAccessRequestInstanceDialog = () => {
    dispatch({
      type: ACTION_TYPE.closeDetailAccessRequestInstanceDialog,
      payload: {
        detailAccessRequestInstance: undefined,
        openDetailAccessRequestInstanceDialog: false,
      },
    })
  }

  const handleOpenSubmitAccessRequestDialog = () => {
    dispatch({
      type: ACTION_TYPE.openSubmitAccesRequestDialog,
      payload: {
        openSubmitAccesRequestDialog: true,
      },
    })
  }

  const handleCloseSubmitAccessRequestDialog = () => {
    dispatch({
      type: ACTION_TYPE.closeSubmitAccesRequestDialog,
      payload: {
        openSubmitAccesRequestDialog: false,
        comment: '',
        duration: 'unlimited',
      },
    })
  }

  const handleOpenCancelAccessRequestDialog = () => {
    dispatch({
      type: ACTION_TYPE.openCancelAccessRequestDialog,
      payload: {
        openCancelAccessRequestDialog: true,
      },
    })
  }

  const handleCloseCancelAccessRequestDialog = () => {
    dispatch({
      type: ACTION_TYPE.closeCancelAccessRequestDialog,
      payload: {
        openCancelAccessRequestDialog: false,
      },
    })
  }

  const handleSubmitAccessRequest = useCallback(async () => {
    try {
      const postRes = await postAccessRequestInstanceCreate({
        sessionToken: state.sessionToken,
        target_type: state.accessItemTabType,
        target_id: state.selectedAccessItemIds.join(','),
        user_ip: ipAddress || '',
        comment: state.comment,
        duration: state.duration,
      })
      if (postRes.status !== 200) {
        if (postRes.reason === 'could not find enduser') {
          navigate('/login', { replace: true })
          return
        }
        dispatch({
          type: ACTION_TYPE.submitAccessRequest,
          payload: {
            openSubmitAccesRequestDialog: false,
            comment: '',
            duration: 'unlimited',
            selectedAccessRequestInstanceIds: [],
          },
        })
        openSnackbar(translateErrorMessage(postRes.reason, 'create'))
        return
      }

      const getRes = await getAccessRequestInstances({
        sessionToken: state.sessionToken,
        type: 'requested_by',
      })
      if (getRes.status !== 200) {
        if (getRes.reason === 'could not find enduser') {
          navigate('/login', { replace: true })
          return
        }
        dispatch({
          type: ACTION_TYPE.submitAccessRequest,
          payload: {
            openSubmitAccesRequestDialog: false,
            comment: '',
            duration: 'unlimited',
            selectedAccessRequestInstanceIds: [],
          },
        })
        openSnackbar(translateErrorMessage(getRes.reason, 'get'))
        return
      }

      dispatch({
        type: ACTION_TYPE.submitAccessRequest,
        payload: {
          openSubmitAccesRequestDialog: false,
          comment: '',
          duration: 'unlimited',
          selectedAccessRequestInstanceIds: [],
          accessRequestInstances: [...getRes.instances].reverse(),
        },
      })
      openSnackbar('申請しました')
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.submitAccessRequest,
        payload: {
          openSubmitAccesRequestDialog: false,
          comment: '',
          duration: 'unlimited',
          selectedAccessRequestInstanceIds: [],
        },
      })
      openSnackbar(
        'システムエラーが発生しました。サポート担当までご連絡ください。',
      )
      throw error
    }
  }, [
    openSnackbar,
    ipAddress,
    state.sessionToken,
    state.accessItemTabType,
    state.selectedAccessItemIds,
    state.comment,
    state.duration,
  ])

  const handleCancelAccessRequest = useCallback(async () => {
    try {
      const responses = await Promise.all(
        state.selectedAccessRequestInstanceIds.map((id) =>
          putAccessRequestInstanceCancel({
            sessionToken: state.sessionToken,
            user_ip: ipAddress || '',
            id: id.toString(),
          }),
        ),
      )
      if (responses.some((r) => r.status !== 200)) {
        if (responses.some((r) => r.reason === 'could not find enduser')) {
          navigate('/login', { replace: true })
          return
        }
        dispatch({
          type: ACTION_TYPE.cancelAccessRequest,
          payload: {
            openCancelAccessRequestDialog: false,
            selectedAccessRequestInstanceIds: [],
          },
        })
        openSnackbar(
          translateErrorMessage(
            responses.find((r) => r.status !== 200)?.reason,
            'cancel',
          ),
        )
        return
      }

      const getRes = await getAccessRequestInstances({
        sessionToken: state.sessionToken,
        type: 'requested_by',
      })
      if (getRes.status !== 200) {
        if (getRes.reason === 'could not find enduser') {
          navigate('/login', { replace: true })
          return
        }
        dispatch({
          type: ACTION_TYPE.cancelAccessRequest,
          payload: {
            openCancelAccessRequestDialog: false,
            selectedAccessRequestInstanceIds: [],
          },
        })
        openSnackbar(translateErrorMessage(getRes.reason, 'get'))
        return
      }

      dispatch({
        type: ACTION_TYPE.cancelAccessRequest,
        payload: {
          openCancelAccessRequestDialog: false,
          selectedAccessRequestInstanceIds: [],
          accessRequestInstances: [...getRes.instances].reverse(),
        },
      })
      openSnackbar('取り下げました')
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.cancelAccessRequest,
        payload: {
          openCancelAccessRequestDialog: false,
          selectedAccessRequestInstanceIds: [],
        },
      })
      openSnackbar(
        'システムエラーが発生しました。サポート担当までご連絡ください。',
      )
      throw error
    }
  }, [
    openSnackbar,
    state.selectedAccessRequestInstanceIds,
    ipAddress,
    state.sessionToken,
  ])

  const handleClickSubmitAccessRequest = useCallback(async () => {
    setIsLoading(true)
    await handleSubmitAccessRequest()
    setIsLoading(false)
  }, [handleSubmitAccessRequest])

  const handleClickCancelAccessRequest = useCallback(async () => {
    setIsLoading(true)
    await handleCancelAccessRequest()
    setIsLoading(false)
  }, [handleCancelAccessRequest])

  const accessRoleColumns = useMemo(
    () =>
      accessRoleGridColumns({
        checkboxColumn: {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          filterable: true,
          hideable: false,
          renderHeader: () => <></>,
        },
      }),
    [],
  )
  const accessProfileColumns = useMemo(
    () =>
      accessProfileGridColumns({
        resourceApps: state.resourceApps,
        resources: state.resources,
        checkboxColumn: {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          filterable: true,
          hideable: false,
          renderHeader: () => <></>,
        },
      }),
    [state.resourceApps, state.resources],
  )
  const entitlementColumns = useMemo(
    () =>
      entitlementGridColumns({
        resourceApps: state.resourceApps,
        resources: state.resources,
        appEntitlementSchemas: state.appEntitlementSchemas,
        checkboxColumn: {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          filterable: true,
          hideable: false,
          renderHeader: () => <></>,
        },
      }),
    [state.resourceApps, state.resources, state.appEntitlementSchemas],
  )

  const accessRequestInstanceColumns = useMemo(
    () =>
      accessRequestInstanceGridColumns({
        checkboxColumn: {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          filterable: true,
          hideable: false,
        },
        gridActionItems: (row) => [
          <IconButton
            className="focus:outline-none"
            onClick={() => handleOpenDetailAccessRequestInstanceDialog(row)}
          >
            <ZoomIn />
          </IconButton>,
        ],
      }),
    [],
  )

  interface detailRequestedObject {
    logoImageUrl: string | undefined
    title: string
    subheader: string
  }

  const detailRequestedObject: detailRequestedObject | undefined =
    useMemo(() => {
      if (
        state.detailAccessRequestInstance?.requested_object.type ===
        'access_role'
      ) {
        const accessRole = state.accessRoles.find(
          (role) =>
            role.id === state.detailAccessRequestInstance?.requested_object.id,
        )
        return {
          logoImageUrl: undefined,
          title: '権限ロール',
          subheader: `${accessRole?.name ?? '---'}`,
        }
      } else if (
        state.detailAccessRequestInstance?.requested_object.type ===
        'access_profile'
      ) {
        const accessProfile = state.accessProfiles.find(
          (profile) =>
            profile.id ===
            state.detailAccessRequestInstance?.requested_object.id,
        )
        const resource = state.resources.find(
          (r) => r.id === accessProfile?.resource_id,
        )
        const app = state.resourceApps.find((a) => a.id === resource?.app_id)
        const titleFirst = '権限ポリシー'
        const titleSecond =
          resource !== undefined ? `> ${resource.name}` : '> ---'
        return {
          logoImageUrl: app?.logo_image_url,
          title: `${titleFirst} ${titleSecond}`,
          subheader: accessProfile?.name ?? '---',
        }
      } else if (
        state.detailAccessRequestInstance?.requested_object.type ===
        'entitlement'
      ) {
        const entitlement = state.entitlements.find(
          (entitlement) =>
            entitlement.id ===
            state.detailAccessRequestInstance?.requested_object.id,
        )
        const resource = state.resources.find(
          (r) => r.id === entitlement?.resource_id,
        )
        const app = state.resourceApps.find((a) => a.id === resource?.app_id)
        const schema = state.appEntitlementSchemas
          .find((aes) => aes.app_id === resource?.app_id)
          ?.attribute_schemas.find(
            (as) => as.object_type === entitlement?.object_type,
          )
        const titleFirst = '権限'
        const titleSecond =
          resource !== undefined ? `> ${resource.name}` : '> ---'
        const titleThird = schema !== undefined ? `> ${schema.label}` : '> ---'
        return {
          logoImageUrl: app?.logo_image_url,
          title: `${titleFirst} ${titleSecond} ${titleThird}`,
          subheader: entitlement?.label ?? '---',
        }
      } else {
        return undefined
      }
    }, [
      state.detailAccessRequestInstance,
      state.accessRoles,
      state.accessProfiles,
      state.entitlements,
      state.resources,
      state.resourceApps,
      state.appEntitlementSchemas,
    ])

  return (
    <>
      <Helmet>
        <title data-test="accessRequestPage">LOCKED マイページ</title>
      </Helmet>
      <div className="relative min-h-screen" style={{ background: '#E5E5E5' }}>
        {state.windowWidth && state.windowWidth < 1000 ? (
          <HeaderSmallWithAccessRequest />
        ) : (
          <div className="fixed w-full" style={{ zIndex: 1 }}>
            <UserInfoPart
              teamName={state.teamName}
              userName={state.userName}
            ></UserInfoPart>
            <LogoPartForAccessRequest />
          </div>
        )}
        <div
          className="w-full"
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            height: 150,
            paddingBottom: 5,
          }}
        ></div>
        <div className="mx-auto" style={{ maxWidth: LARGE_APP_LIST_MAX_WIDTH }}>
          <Box display="flex" marginBottom={2}>
            <div
              className="flex pl-4 items-center"
              style={{
                width: '100%',
                height: 32,
              }}
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 5.5H5V0.5H0V5.5ZM7.5 20.5H12.5V15.5H7.5V20.5ZM0 20.5H5V15.5H0V20.5ZM0 13H5V8H0V13ZM7.5 13H12.5V8H7.5V13ZM15 0.5V5.5H20V0.5H15ZM7.5 5.5H12.5V0.5H7.5V5.5ZM15 13H20V8H15V13ZM15 20.5H20V15.5H15V20.5Z"
                  fill="#2460D1"
                />
              </svg>
              <span
                className="ml-2 text-lg"
                style={{ fontWeight: 700, color: '#4F4F4F' }}
              >
                権限の申請
              </span>
            </div>
          </Box>
          <div
            style={{
              width: '100%',
              display: 'grid',
              columnGap: 10,
              rowGap: 10,
            }}
          ></div>
          <Box position="relative" boxShadow="0px 2px 2px rgba(0, 0, 0, 0.17)">
            <Tabs
              value={state.tabType}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeTab}
              sx={{ backgroundColor: 'white' }} // この行を使用してスタイルを適用
            >
              <Tab
                value={tabTypes.ACCESS_ITEMS}
                label={
                  <Box display="flex" alignItems="center">
                    <Publish />
                    <Box ml={1}>権限の申請</Box>
                  </Box>
                }
                className="focus:outline-none"
              />
              <Tab
                value={tabTypes.ACCESS_REQUEST_INSTANCES}
                label={
                  <Box display="flex" alignItems="center">
                    <Edit />
                    <Box ml={1}>申請した履歴の一覧</Box>
                  </Box>
                }
                className="focus:outline-none"
              />
            </Tabs>
            <Divider />
            {state.tabType === tabTypes.ACCESS_ITEMS && (
              <Box sx={{ display: 'flex', backgroundColor: 'white', p: 2 }}>
                <List sx={{ width: '20%', p: 0 }}>
                  <ListItemButton
                    selected={
                      state.accessItemTabType === accessItemTabTypes.ACCESS_ROLE
                    }
                    onClick={() =>
                      handleChangeAccessItemTab(accessItemTabTypes.ACCESS_ROLE)
                    }
                  >
                    <ListItemText
                      primary="権限ロール"
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        color: grey['800'],
                      }}
                    />
                    <ListItemIcon sx={{ justifyContent: 'end' }}>
                      <KeyboardArrowRight />
                    </ListItemIcon>
                  </ListItemButton>
                  <ListItemButton
                    selected={
                      state.accessItemTabType ===
                      accessItemTabTypes.ACCESS_PROFILE
                    }
                    onClick={() =>
                      handleChangeAccessItemTab(
                        accessItemTabTypes.ACCESS_PROFILE,
                      )
                    }
                  >
                    <ListItemText
                      primary="権限ポリシー"
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        color: grey['800'],
                      }}
                    />
                    <ListItemIcon sx={{ justifyContent: 'end' }}>
                      <KeyboardArrowRight />
                    </ListItemIcon>
                  </ListItemButton>
                  <ListItemButton
                    selected={
                      state.accessItemTabType === accessItemTabTypes.ENTITLEMENT
                    }
                    onClick={() =>
                      handleChangeAccessItemTab(accessItemTabTypes.ENTITLEMENT)
                    }
                  >
                    <ListItemText
                      primary="権限"
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        color: grey['800'],
                      }}
                    />
                    <ListItemIcon sx={{ justifyContent: 'end' }}>
                      <KeyboardArrowRight />
                    </ListItemIcon>
                  </ListItemButton>
                </List>
                <Box paddingLeft={3} />
                <Box
                  sx={{
                    display: 'flex',
                    height: '60vh',
                    width: '80%',
                  }}
                >
                  {state.accessItemTabType ===
                    accessItemTabTypes.ACCESS_ROLE && (
                    <DataGridPremium
                      loading={isLoading}
                      disableRowGrouping
                      disableColumnMenu
                      checkboxSelection
                      selectionModel={state.selectedAccessItemIds}
                      onSelectionModelChange={handleChangeSelectedAccessItemIds}
                      localeText={
                        jaJP.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={state.accessRoles}
                      columns={accessRoleColumns}
                      hideFooter
                      components={{
                        Toolbar: (props) => (
                          <GridToolbarContainer
                            {...props}
                            sx={{ pt: 2, pb: 2 }}
                          >
                            <GridToolbarQuickFilter />
                            <Box flex={1} />
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={
                                state.selectedAccessItemIds.length === 0
                              }
                              onClick={handleOpenSubmitAccessRequestDialog}
                              sx={{ marginLeft: 'auto' }} // 右寄せで表示
                            >
                              申請する
                            </Button>
                          </GridToolbarContainer>
                        ),
                        NoRowsOverlay: (props) => (
                          <GridOverlay {...props}>
                            権限がありません。
                          </GridOverlay>
                        ),
                        NoResultsOverlay: (props) => (
                          <GridOverlay {...props}>
                            検索しましたが、見つかりませんでした。
                          </GridOverlay>
                        ),
                      }}
                      initialState={{
                        pinnedColumns: {
                          left: [GRID_CHECKBOX_SELECTION_FIELD],
                          right: [GRID_ACTIONS_COLUMN_TYPE],
                        },
                      }}
                      // WARNING: ダイアログ内にdataGridがあり、セルをクリックしたときにrowのfieldが取得できないバグ
                      // https://github.com/mui/mui-x/issues/7943
                      onCellClick={(_, e) => e.stopPropagation()}
                      sx={dataGridSxProps}
                    />
                  )}
                  {state.accessItemTabType ===
                    accessItemTabTypes.ACCESS_PROFILE && (
                    <DataGridPremium
                      loading={isLoading}
                      disableRowGrouping
                      disableColumnMenu
                      checkboxSelection
                      selectionModel={state.selectedAccessItemIds}
                      onSelectionModelChange={handleChangeSelectedAccessItemIds}
                      localeText={
                        jaJP.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={state.accessProfiles}
                      columns={accessProfileColumns}
                      hideFooter
                      components={{
                        Toolbar: (props) => (
                          <GridToolbarContainer
                            {...props}
                            sx={{ pt: 2, pb: 2 }}
                          >
                            <GridToolbarQuickFilter />
                            <Box flex={1} />
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={
                                state.selectedAccessItemIds.length === 0
                              }
                              onClick={handleOpenSubmitAccessRequestDialog}
                              sx={{ marginLeft: 'auto' }} // 右寄せで表示
                            >
                              申請する
                            </Button>
                          </GridToolbarContainer>
                        ),
                        NoRowsOverlay: (props) => (
                          <GridOverlay {...props}>
                            権限がありません。
                          </GridOverlay>
                        ),
                        NoResultsOverlay: (props) => (
                          <GridOverlay {...props}>
                            検索しましたが、見つかりませんでした。
                          </GridOverlay>
                        ),
                      }}
                      initialState={{
                        pinnedColumns: {
                          left: [GRID_CHECKBOX_SELECTION_FIELD],
                          right: [GRID_ACTIONS_COLUMN_TYPE],
                        },
                      }}
                      // WARNING: ダイアログ内にdataGridがあり、セルをクリックしたときにrowのfieldが取得できないバグ
                      // https://github.com/mui/mui-x/issues/7943
                      onCellClick={(_, e) => e.stopPropagation()}
                      sx={dataGridSxProps}
                    />
                  )}
                  {state.accessItemTabType ===
                    accessItemTabTypes.ENTITLEMENT && (
                    <DataGridPremium
                      loading={isLoading}
                      disableRowGrouping
                      disableColumnMenu
                      checkboxSelection
                      selectionModel={state.selectedAccessItemIds}
                      onSelectionModelChange={handleChangeSelectedAccessItemIds}
                      localeText={
                        jaJP.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={state.entitlements}
                      columns={entitlementColumns}
                      hideFooter
                      components={{
                        Toolbar: (props) => (
                          <GridToolbarContainer
                            {...props}
                            sx={{ pt: 2, pb: 2 }}
                          >
                            <GridToolbarQuickFilter />
                            <Box flex={1} />
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={
                                state.selectedAccessItemIds.length === 0
                              }
                              onClick={handleOpenSubmitAccessRequestDialog}
                              sx={{ marginLeft: 'auto' }} // 右寄せで表示
                            >
                              申請する
                            </Button>
                          </GridToolbarContainer>
                        ),
                        NoRowsOverlay: (props) => (
                          <GridOverlay {...props}>
                            権限がありません。
                          </GridOverlay>
                        ),
                        NoResultsOverlay: (props) => (
                          <GridOverlay {...props}>
                            検索しましたが、見つかりませんでした。
                          </GridOverlay>
                        ),
                      }}
                      initialState={{
                        pinnedColumns: {
                          left: [GRID_CHECKBOX_SELECTION_FIELD],
                          right: [GRID_ACTIONS_COLUMN_TYPE],
                        },
                      }}
                      // WARNING: ダイアログ内にdataGridがあり、セルをクリックしたときにrowのfieldが取得できないバグ
                      // https://github.com/mui/mui-x/issues/7943
                      onCellClick={(_, e) => e.stopPropagation()}
                      sx={dataGridSxProps}
                    />
                  )}
                </Box>
              </Box>
            )}
            {state.tabType === tabTypes.ACCESS_REQUEST_INSTANCES && (
              <Box
                sx={{
                  height: '60vh',
                  width: '100%',
                  background: 'white',
                  p: 2,
                }}
              >
                <DataGridPremium
                  loading={isLoading}
                  disableRowGrouping
                  disableColumnMenu
                  checkboxSelection
                  selectionModel={state.selectedAccessRequestInstanceIds}
                  onSelectionModelChange={
                    handleChangeSelectedAccessRequestInstanceIds
                  }
                  isRowSelectable={isAccessRequestInstanceRowSelectable}
                  localeText={
                    jaJP.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={state.accessRequestInstances}
                  columns={accessRequestInstanceColumns}
                  hideFooter
                  components={{
                    Toolbar: (props) => (
                      <>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          sx={{ flexGrow: 1, textAlign: 'left' }} // 左寄せで表示
                        >
                          申請者として申請した履歴の一覧が確認できます。
                        </Typography>
                        <GridToolbarContainer {...props} sx={{ pt: 2, pb: 2 }}>
                          <GridToolbarQuickFilter />
                          <Box flex={1} />
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={
                              state.selectedAccessRequestInstanceIds.length ===
                              0
                            }
                            onClick={handleOpenCancelAccessRequestDialog}
                            sx={{ marginLeft: 'auto' }}
                          >
                            {state.selectedAccessRequestInstanceIds.length === 0
                              ? '取り下げる'
                              : `${state.selectedAccessRequestInstanceIds.length}件を取り下げる`}
                          </Button>
                        </GridToolbarContainer>
                      </>
                    ),
                    NoRowsOverlay: (props) => (
                      <GridOverlay {...props}>
                        申請した履歴がありません。
                      </GridOverlay>
                    ),
                    NoResultsOverlay: (props) => (
                      <GridOverlay {...props}>
                        検索しましたが、見つかりませんでした。
                      </GridOverlay>
                    ),
                  }}
                  initialState={{
                    pinnedColumns: {
                      left: [GRID_CHECKBOX_SELECTION_FIELD],
                      right: [GRID_ACTIONS_COLUMN_TYPE],
                    },
                  }}
                  // WARNING: ダイアログ内にdataGridがあり、セルをクリックしたときにrowのfieldが取得できないバグ
                  // https://github.com/mui/mui-x/issues/7943
                  onCellClick={(_, e) => e.stopPropagation()}
                  sx={dataGridSxProps}
                />
              </Box>
            )}
          </Box>
          <Dialog
            fullWidth
            maxWidth="sm"
            open={state.openSubmitAccesRequestDialog}
            onClose={handleCloseSubmitAccessRequestDialog}
          >
            <DialogTitle>{`${
              state.accessItemTabType === accessItemTabTypes.ACCESS_ROLE
                ? state.accessRoles.find((r) =>
                    state.selectedAccessItemIds.includes(r.id),
                  )?.name
                : state.accessItemTabType === accessItemTabTypes.ACCESS_PROFILE
                ? state.accessProfiles.find((p) =>
                    state.selectedAccessItemIds.includes(p.id),
                  )?.name
                : state.accessItemTabType === accessItemTabTypes.ENTITLEMENT
                ? state.entitlements.find((e) =>
                    state.selectedAccessItemIds.includes(e.id),
                  )?.label
                : null
            }を申請する`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                以下の項目を入力して「申請する」を押してください。
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="comment"
                type="text"
                label="コメント"
                fullWidth
                multiline
                variant="outlined" // ここを変更して入力欄に枠を表示
                rows={4} // 複数行テキストのための行数指定
                value={state.comment}
                onChange={(e) =>
                  dispatch({
                    type: ACTION_TYPE.changeComment,
                    payload: {
                      comment: e.target.value,
                    },
                  })
                }
              />
              <FormControl fullWidth margin="dense">
                <InputLabel id="select-expired_at-label">期間</InputLabel>
                <Select
                  size="small"
                  label="期間"
                  defaultValue={'unlimited'}
                  value={state.duration}
                  onChange={(e) =>
                    dispatch({
                      type: ACTION_TYPE.changeDuration,
                      payload: {
                        duration: e.target.value as
                          | '1day'
                          | '1week'
                          | '1month'
                          | '3months'
                          | 'unlimited',
                      },
                    })
                  }
                >
                  <MenuItem value="unlimited">無期限</MenuItem>
                  <MenuItem value="1day">1日</MenuItem>
                  <MenuItem value="1week">1週間</MenuItem>
                  <MenuItem value="1month">1ヶ月</MenuItem>
                  <MenuItem value="3months">3ヶ月</MenuItem>
                </Select>
                <FormHelperText>
                  権限が必要な期間を選択してください。
                </FormHelperText>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSubmitAccessRequestDialog}>
                キャンセル
              </Button>
              <Button onClick={handleClickSubmitAccessRequest}>
                {isLoading ? <CircularProgress size={18} /> : '申請する'}
              </Button>
            </DialogActions>
          </Dialog>
          {state.detailAccessRequestInstance !== undefined && (
            <Dialog
              fullWidth
              maxWidth="md"
              open={state.openDetailAccessRequestInstanceDialog}
              onClose={handleCloseDetailAccessRequestInstanceDialog}
            >
              <DialogTitle>申請内容の詳細</DialogTitle>
              <DialogContent>
                <Paper variant="outlined">
                  <Box sx={{ padding: '15px 24px' }}>
                    <Typography variant="h6">申請内容</Typography>
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      申請の詳細情報が確認できます。
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      申請ID
                    </Typography>
                    <Typography>
                      {state.detailAccessRequestInstance.id}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      ステータス
                    </Typography>
                    <Typography
                      color={translateStatusColor(
                        state.detailAccessRequestInstance.status,
                      )}
                    >
                      {`${translateStatus(
                        state.detailAccessRequestInstance.status,
                      )} (${getApprovalsProgress(
                        state.detailAccessRequestInstance.approvals,
                      )})`}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      権限
                    </Typography>
                    {detailRequestedObject === undefined ? (
                      <Typography>---</Typography>
                    ) : (
                      <Card sx={{ boxShadow: 'none' }}>
                        <CardHeader
                          sx={{ p: 0 }}
                          avatar={
                            detailRequestedObject.logoImageUrl !== undefined ? (
                              <Avatar
                                alt={detailRequestedObject.logoImageUrl}
                                src={detailRequestedObject.logoImageUrl}
                                imgProps={{
                                  sx: {
                                    objectFit: 'contain',
                                    verticalAlign: 'text-bottom',
                                  },
                                }}
                              />
                            ) : null
                          }
                          title={detailRequestedObject.title}
                          titleTypographyProps={{ variant: 'caption' }}
                          subheader={detailRequestedObject.subheader}
                          subheaderTypographyProps={{
                            variant: 'body1',
                            color: 'text.primary',
                          }}
                        />
                      </Card>
                    )}
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      申請者
                    </Typography>
                    <Typography>
                      {state.detailAccessRequestInstance.requested_by.name !==
                      ''
                        ? state.detailAccessRequestInstance.requested_by.name
                        : '---'}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      コメント
                    </Typography>
                    <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                      {state.detailAccessRequestInstance.requested_by
                        .comment !== ''
                        ? state.detailAccessRequestInstance.requested_by.comment
                        : '---'}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      権限の有効期限
                    </Typography>
                    <Typography>
                      {state.detailAccessRequestInstance.expired_at !== null
                        ? formatDateTime(
                            state.detailAccessRequestInstance.expired_at,
                          )
                        : '---'}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      申請手続き開始日時
                    </Typography>
                    <Typography>
                      {state.detailAccessRequestInstance.started_at !== null
                        ? formatDateTime(
                            state.detailAccessRequestInstance.started_at,
                          )
                        : '---'}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      申請手続き終了日時
                    </Typography>
                    <Typography>
                      {state.detailAccessRequestInstance.finished_at !== null
                        ? formatDateTime(
                            state.detailAccessRequestInstance.finished_at,
                          )
                        : '---'}
                    </Typography>
                  </Box>
                </Paper>
                <Box marginBottom={2} />
                <Paper variant="outlined">
                  <Box sx={{ padding: '15px 24px' }}>
                    <Typography variant="h6">承認ステップ</Typography>
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      各ステップごとの状況が確認できます。
                    </Typography>
                  </Box>
                  {state.detailAccessRequestInstance.approvals.map(
                    (approval, index) => (
                      <Paper key={index} variant="outlined" sx={{ m: 2 }}>
                        <Box sx={{ padding: '15px 24px' }}>
                          <Typography variant="h6">{`ステップ${
                            index + 1
                          }`}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            padding: '15px 24px',
                            flexDirection: { xs: 'column', sm: 'row' },
                          }}
                        >
                          <Typography variant="caption" sx={{ minWidth: 250 }}>
                            {approval.status === 'in_review' ||
                            approval.status === 'pending'
                              ? '承認者(候補)'
                              : '承認者'}
                          </Typography>
                          <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                            {approval.status === 'in_review' ||
                            approval.status === 'pending'
                              ? approval.approvers
                                  .map((a) => a.name)
                                  .join(' / ')
                              : approval.approved_by[0]?.name}
                          </Typography>
                        </Box>
                        <Divider variant="middle" />
                        <Box
                          sx={{
                            display: 'flex',
                            padding: '15px 24px',
                            flexDirection: { xs: 'column', sm: 'row' },
                          }}
                        >
                          <Typography variant="caption" sx={{ minWidth: 250 }}>
                            ステータス
                          </Typography>
                          <Typography
                            color={translateApprovalStatusColor(
                              approval.status,
                            )}
                          >
                            {`${translateApprovalStatus(approval.status)}`}
                          </Typography>
                        </Box>
                        <Divider variant="middle" />
                        <Box
                          sx={{
                            display: 'flex',
                            padding: '15px 24px',
                            flexDirection: { xs: 'column', sm: 'row' },
                          }}
                        >
                          <Typography variant="caption" sx={{ minWidth: 250 }}>
                            コメント
                          </Typography>
                          <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                            {approval.approved_by[0]?.comment !== undefined &&
                            approval.approved_by[0]?.comment !== ''
                              ? approval.approved_by[0]?.comment
                              : '---'}
                          </Typography>
                        </Box>
                      </Paper>
                    ),
                  )}
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDetailAccessRequestInstanceDialog}>
                  閉じる
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <Dialog
            open={state.openCancelAccessRequestDialog}
            onClose={handleCloseCancelAccessRequestDialog}
          >
            <DialogTitle>申請を取り下げる</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {state.selectedAccessRequestInstanceIds.length}
                件の申請を取り下げます。
                <br />
                よろしいですか？
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCancelAccessRequestDialog}>
                キャンセル
              </Button>
              <Button onClick={handleClickCancelAccessRequest}>
                {isLoading ? <CircularProgress size={18} /> : '取り下げる'}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="pb-1 mt-8 absolute bottom-0" style={{ width: '100%' }}>
          <div style={{ height: 20 }} className="justify-center flex ">
            <span
              className="text-xs align-text-bottom"
              style={{ color: '#828282' }}
            >
              Onetap, Inc &copy {new Date().getFullYear()}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
