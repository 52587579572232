import { fullUrl } from 'scripts/fullUrl'
import { CreateAccessRequestInstancesResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'

export const postAccessRequestInstanceCreate = async (params: {
  sessionToken: string
  target_type: 'access_role' | 'access_profile' | 'entitlement'
  target_id: string
  duration: '1day' | '1week' | '1month' | '3months' | 'unlimited'
  user_ip: string
  comment?: string | null
}): Promise<CreateAccessRequestInstancesResponse> => {
  const res = await axiosClient.post<CreateAccessRequestInstancesResponse>(
    `${fullUrl('api')}/access_request/access_request_instances`,
    params,
  )
  return res.data
}
