import React from 'react'
import LockedLogo from 'images/lockedlogo.png'
import axios from 'axios'
import { LogoutResponse } from 'types/ApiResponse'
import { cookies } from 'scripts/cookies'
import { fullUrl } from 'scripts/fullUrl'
import { navigate } from 'gatsby'
import { Button, Menu, MenuItem } from '@mui/material'
import { LARGE_APP_LIST_MAX_WIDTH } from 'presentation/pages/apps/AppExists/Large/AppList'

type Props = Record<string, never>

export const LogoPartForAccessRequest: React.FC<Props> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickLogout = () => {
    const sessionToken = cookies.get('session_token')
    axios
      .get<LogoutResponse>(`${fullUrl()}/logout`, {
        params: {
          session_token: sessionToken,
        },
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        cookies.removeSessionToken()
        navigate('/login')
      })
  }

  const LogoPartStyle: React.CSSProperties = {
    background: '#FFFFFF',
    boxShadow: '0px 2px 6px rgba(10, 6, 17, 0.15)',
    height: 52,
  }

  return (
    <div className="flex items-center justify-center" style={LogoPartStyle}>
      <div
        style={{
          width: LARGE_APP_LIST_MAX_WIDTH,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <div />
        <img
          src={LockedLogo}
          style={{
            width: 96,
            display: 'block',
          }}
        />
        <Button
          data-testid="toggle-show-setting-menu-button"
          variant="outlined"
          color="inherit"
          sx={{ justifySelf: 'end', marginRight: '12px' }}
          onClick={handleClick}
        >
          設定
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            data-testid="toggle-show-app-list-item-menu-button"
            onClick={() => navigate('/apps')}
          >
            アプリ一覧
          </MenuItem>
          <MenuItem
            data-testid="toggle-show-app-list-item-menu-button"
            onClick={() => navigate('/accessRequestApprove')}
          >
            権限の承認
          </MenuItem>
          <MenuItem data-testid="logout-button" onClick={handleClickLogout}>
            ログアウト
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}
